import React, { Component } from 'react';

class About extends Component {
  render() {

    if(this.props.data){
      var name = this.props.data.full_name;
      var profilepic= "images/"+this.props.data.image;
      var bio = this.props.data.bio;
      var city = this.props.data.address.city;
      var state = this.props.data.address.state;
      var email = this.props.data.email;
      var resumeDownload = this.props.data.resumedownload;
      // Replace newlines with <br />
      var formattedBio = bio.split('\n').map((line, index) => (
         <React.Fragment key={index}>
            {line}
            <br />
            <br />
         </React.Fragment>
         ));
    }

    return (
      <section id="about">
      <div className="row">
         <div className="three columns">
            <img className="profile-pic"
               src={profilepic} alt="Kamran Profile Pic" 
               onMouseOver={() => console.log('click')} 
            />
         </div>
         <div className="nine columns main-col">
            <h2>About Me</h2>

            <p>{formattedBio}</p>
            <div className="row">
               <div className="columns contact-details">
                  <h2>Contact Details</h2>
                  <p className="address">
						   <span>{name}</span><br />
						   <span> {city} {state} </span><br />
                     <span>{email}</span>
					   </p>
               </div>
               <div className="columns download">
                  <p>
                     <a href={resumeDownload} target="_blank" className="button"><i className="fa fa-download"></i>View Resume</a>
                  </p>
               </div>
            </div>
         </div>
      </div>

   </section>
    );
  }
}

export default About;
